import React, { useEffect, useState } from 'react';

import { CustomerForm } from './CustomerForm/CustomerForm';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';
import { getData } from '../../services/httpget';
import { ResponseDataDeal } from '../../type/type';

import style from './GetPayment.module.css';

export const GetPayment = () => {
  const [state, setState] = useState({
    email: '',
    customerName: '',
    phoneNumber: '',
    telegram: '',
    termsChecked: false,
  });
  const { searchParams } = new URL(document.location.href);
  const tariffSlug = searchParams.get('tariff');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState();
  const [dealData, setDealData] = useState<ResponseDataDeal | null>(null);

  const redirect = () => {
    window.location.href = 'https://p2p-academy.online';
  };

  const handleResponse = (res: ResponseDataDeal) => {
    if (res.deal.result.success) {
      setDealData(res);
    } else {
      // redirect();
    }
  };

  const validateTariffs = (allTariffs: any) => {
    if (tariffSlug && Object.keys(allTariffs).includes(tariffSlug)) {
      const usedTariff = allTariffs[tariffSlug];
      setTitle(usedTariff?.title);
      setPrice(usedTariff?.usdtToPay);
    } else {
      redirect();
    }
  };

  const getTariffs = () => {
    try {
      getData('/api/tariffs').then((res) => {
        const allTariffs = res;
        validateTariffs(allTariffs);
      });
    } catch (error) { /* empty */ }
  };

  useEffect(() => {
    getTariffs();
  }, []);

  const [visible, setVisible] = useState(false);

  if (!title) {
    return <div />;
  }

  return (
    <div className={style.wrapper}>
      {visible && dealData !== null ? (
        <PaymentMethods
          price={price}
          title={title}
          customer={state}
          dealData={dealData}
          tariffSlug={tariffSlug}
        />
      ) : (
        <CustomerForm
          title={title}
          onResponseDeal={handleResponse}
          tariffSlug={tariffSlug}
          customerFormData={state}
          setCustomerData={setState}
          onSubmit={setVisible}
        />
      )}
    </div>
  );
};
