import React from 'react';
import { Button } from '../../../atoms/Button';
import style from './Method.module.scss';

type Props = {
  method: {
    name: string;
    coins: string[];
    id: number;
  }
  onClick: () => void;
};

export const Method = ({ method, onClick }: Props) => {
  const { name, coins, id } = method;
  const clickHandler = () => {
    onClick();
  };

  const imagePath = `${process.env.PUBLIC_URL}/platforms/${name.toLowerCase()}.png`;

  return (
    <div className={style.wrapper}>
      <div>{name}</div>
      <div className={style.actionBox}>
        { ![3, 4, 5, 6].some((i) => i === id) ? (
          <div className={style.image} style={{ backgroundImage: `url(${imagePath})` }} />
        ) : (
          <div className={style.pay_buy_crypto}>
            <h3 className={style.pay_buy_crypto_text}>
              Оплата криптовалютой
            </h3>
          </div>
        )}
        <Button onClick={clickHandler} variant="primary" className={style.wideButton}>
          <div className={style.pay_button}>Оплатить</div>
          <div>{coins.join(', ')}</div>
        </Button>
      </div>
    </div>
  );
};
