import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { GetPayment } from './components/GetPayment/GetPayment';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route path="*" element={<GetPayment />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
