import React from 'react';
import PaymentCard from '../../../atoms/Cards/PaymentCard/PaymentCard';

type Props = {
  paymentData: any;
}

const Coinpayments = (props: Props) => {
  const {
    paymentData,
  } = props;

  const {
    qrCode,
    address,
    cryptoCost,
    cryptoCurrency,
  } = paymentData;

  const cryptoCurrencyMin = cryptoCurrency.split('.')[0];
  const floatCost = parseFloat(cryptoCost);

  return (
    <PaymentCard>
      <span>
        ВНИМАНИЕ!
      </span>
      <span>
        УЧИТЫВАЙТЕ КОМИССИЮ!
      </span>
      <br />
      <span>
        Для активации тарифа на кошелек должно поступить ровно
        {` ${floatCost}`}
        {` ${cryptoCurrencyMin}`}
      </span>
      <br />
      <span>
        Прибавляйте комиссию к вашему платежу. Если тариф стоит
        {` ${floatCost}`}
        {` ${cryptoCurrencyMin}`}
        , а комиссия равна 0.0001
        {` ${cryptoCurrencyMin}`}
        . Вы должны отправить
        {` ${floatCost + 0.0001}`}
        {` ${cryptoCurrencyMin}`}
      </span>
      <br />
      <span>
        Адрес в сети
        {` ${cryptoCurrency}`}
        :
        {` ${address}`}
      </span>
      <img alt={address} src={qrCode} />
      <span>
        {`${cryptoCost}`}
        {` ${cryptoCurrencyMin}`}
      </span>
      <span>
        Требуется подтверждений: 2
      </span>
    </PaymentCard>
  );
};

export default Coinpayments;
