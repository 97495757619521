/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { AccessibleModal } from '../../atoms/AccessibleModal';
import { CloseIcon } from '../../atoms/CloseIcon';
import { CustomerData } from './commonTypes';
import { Method } from './Method/Method';
import { Payment } from './Method/Payment/Payment';

import { ResponseDataDeal } from '../../../type/type';
import { postData } from '../../../services/httpget';
import { Loader } from '../../atoms/Loader/Loader';

import style from './PaymentMethods.module.scss';

const methodsMock = [
  { name: 'Binance', id: 1, coins: ['USDT'] },
  // { name: 'Binance', id: 2, coins: ['BUSD'] },
  { name: 'Перевод по реквизитам', id: 3, coins: ['BTC'] },
  { name: 'Перевод по реквизитам', id: 4, coins: ['BTC BEP20'] },
  { name: 'Перевод по реквизитам', id: 5, coins: ['USDT TRC20'] },
  { name: 'Перевод по реквизитам', id: 6, coins: ['ETH'] },
];

type Props = {
  title: string;
  price?: number;
  customer: CustomerData;
  dealData: ResponseDataDeal;
  tariffSlug: string | null;
}
export const PaymentMethods = ({
  title,
  price,
  customer,
  dealData,
  tariffSlug,
}: Props) => {
  const {
    customerName,
    email,
    telegram,
    phoneNumber,
  } = customer;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [checkId, setCheckId] = useState('');
  const [loading, setLoading] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  const [paymentUrl, setPaymentUrl] = useState('');
  const [paymentData, setPaymentData] = useState({});

  const handleClick = async (id: number) => {
    let cryptoCurrency = 'USDT';
    let equiring = 'Binance';
    // if (id === 2) {
    //   cryptoCurrency = 'BUSD';
    // }
    if (id === 3) {
      equiring = 'CoinPayments';
      cryptoCurrency = 'BTC';
    }
    if (id === 4) {
      equiring = 'CoinPayments';
      cryptoCurrency = 'BTC.BEP20';
    }
    if (id === 5) {
      equiring = 'CoinPayments';
      cryptoCurrency = 'USDT.TRC20';
    }
    if (id === 6) {
      equiring = 'CoinPayments';
      cryptoCurrency = 'ETH';
    }
    setLoading(true);
    toggleVisibility();
    postData('/api/get-payment-links', {
      cryptoCurrency,
      equiring,
      tariffToPay: tariffSlug,
      deal_number: dealData?.deal?.result?.deal_number,
      accountData: {
        ...customer,
        name: customerName,
        phone: phoneNumber,
      },
    }).then((resp) => {
      setLoading(false);
      if (resp.success) {
        setPaymentMethod(id);
        const {
          url,
          address,
          confirmsNeeded,
          cryptoCost,
          cryptoCurrency,
          qrCode,
        } = resp?.data || {};
        const { checkId } = resp || '';
        setPaymentUrl(url);
        setCheckId(checkId);
        setPaymentData({
          address,
          confirmsNeeded,
          cryptoCost,
          cryptoCurrency,
          qrCode,
        });
      } else {
        toast.error(resp?.msg);
        toggleVisibility();
      }
    });
  };

  return (
    <>
      <AccessibleModal
        isOpen={isVisible}
        onRequestClose={() => setIsVisible(false)}
      >
        <CloseIcon
          width="38px"
          height="38px"
          onClick={toggleVisibility}
        />
        <div className={style.modalContainer}>
          {(loading || !title) ? (
            <Loader />
          ) : (
            <Payment
              id={paymentMethod}
              title={title}
              paymentUrl={paymentUrl}
              paymentData={paymentData}
            />
          )}
        </div>
      </AccessibleModal>
      <div className={style.mainContainer}>
        <div className={style.logoPlace}>
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="logo" />
        </div>
        <div className={style.pageHeader}>
          Оплата заказа #
          {dealData?.deal?.result?.deal_number}
        </div>
        <div className={style.orderDetails}>
          <div className={style.order}>
            <p className={style.orderTitle}>Состав заказа:</p>
            <div>Курс &quot;Собственный бизнес на обмене криптовалют&quot;. </div>
            <div>{`Версия 3.0. ${title} ${price} USDT`}</div>
            <p>
              Итого к оплате:
              <span className={style.amount}>
                {` ${price} USDT`}
              </span>
            </p>
          </div>
          <div>
            <p className={style.orderTitle}>
              Данные плательщика
            </p>
            <div className={style.customerData}>
              <div>{customerName}</div>
              <div>{email}</div>
              <p>{telegram}</p>
            </div>
          </div>
        </div>
        <div className={style.methodsContainer}>
          {methodsMock.map((method) => {
            const onClickMethod = () => {
              handleClick(method.id);
            };
            return (
              <Method method={method} onClick={onClickMethod} key={method.id} />
            );
          })}
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  );
};
