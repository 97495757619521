import React from 'react';
import PaymentCard from '../../../atoms/Cards/PaymentCard/PaymentCard';

import s from './BinancePay.module.scss';

type Props = {
  title: string;
  textButton: string;
  paymentUrl?: string;
}

const BinancePay = (props: Props) => {
  const {
    title,
    textButton,
    paymentUrl,
  } = props;

  return (
    <PaymentCard>
      <div className={s.content}>
        <div className={s.title}>{`Тариф ${title}`}</div>
      </div>
      <a href={paymentUrl} target="_blank" rel="noreferrer">
        <button className={s.button_card}>
          {textButton}
        </button>
      </a>
    </PaymentCard>
  );
};

export default BinancePay;
