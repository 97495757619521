import React from 'react';
import BinancePay from '../../BinancePay/BinancePay';
import Coinpayments from '../../Coinpayments/Coinpayments';

type Props = {
  id: number;
  title: string;
  paymentUrl?: string;
  paymentData?: any;
}

export const Payment = ({
  id,
  title,
  paymentUrl,
  paymentData,
}: Props) => {
  switch (id) {
    case 1:
      return (
        <BinancePay
          title={title}
          textButton="Оплатить Binance Pay USDT"
          paymentUrl={paymentUrl}
        />
      );
    // case 2:
    //   return (
    //     <BinancePay
    //       title={title}
    //       textButton="Оплатить Binance Pay BUSD"
    //       paymentUrl={paymentUrl}
    //     />
    //   );
    case 3:
      return (
        <Coinpayments
          paymentData={paymentData}
        />
      );
    case 4:
      return (
        <Coinpayments
          paymentData={paymentData}
        />
      );
    case 5:
      return (
        <Coinpayments
          paymentData={paymentData}
        />
      );
    case 6:
      return (
        <Coinpayments
          paymentData={paymentData}
        />
      );
    default:
      return (
        <div />
      );
  }
};
