import React from 'react';
import styles from './style.module.css';

type Props = {
  width: string;
  height: string;
  onClick: () => void;
};

export const CloseIcon = ({ width, height, onClick }: Props) => (
  <div
    tabIndex={-1}
    onKeyUp={() => {}}
    className={styles.main}
    role="button"
    onClick={onClick}
    style={{ width: `${width}`, height: `${height}` }}
  />
);
