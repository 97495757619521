import React from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    inset: '0',
    border: '0',
    background: '0',
    display: 'flex',
  },
  overlay: {
    zIndex: 30,
    overflow: 'hidden',
    background: 'rgb(0 0 0 / 75%)',
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onAfterOpen?: () => void;
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
  setForvardedContentRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>;
  children: React.ReactNode;
};

export const AccessibleModal = ({
  isOpen,
  onRequestClose,
  onAfterOpen,
  content = {},
  overlay = {},
  children,
  setForvardedContentRef,
}: Props) => (
  <ReactModal
    isOpen={isOpen}
    onAfterOpen={onAfterOpen}
    onRequestClose={onRequestClose}
    shouldCloseOnOverlayClick
    style={{
      content: { ...customStyles.content, ...content },
      overlay: { ...customStyles.overlay, ...overlay },
    }}
    contentLabel="Modal"
    overlayRef={(ref) => {
      if (setForvardedContentRef) {
        return setForvardedContentRef(ref);
      }
      return null;
    }}
  >
    {children}
  </ReactModal>
);
